/**
 * @ngdoc object
 * @name Users
 * @requires $resource, PermissionsSvc
 */

 /* Note: Servies are generally grouped by function (user), and then by the endpoint base (e.g. users vs organizations/users vs divisions/users vs projects/users)*/

(function(){

    'use strict';

    angular
        .module('pulse')
        .factory('Users', Users);

    Users.$inject = ['$resource'];

    function Users ($resource) {

        return {

            User: $resource(
                config.baseURL +'/users/:userID/:ext/',
                {ext: '@ext', userID: '@userID'},
                {
                    getUser: {method:'GET'},
                    registration: {method:'POST', params: {ext:'registration'}},
                    findMe: {method:'GET', params: {ext:'me'}},
                }
            ),

            OrganizationUser: $resource(
                config.baseURL + '/organizations/:organizationID/:ext/:userID/:type',
                {organizationID: '@organizationID', userID: '@userID'},
                {
                    getUsers: {method:'GET', params: {ext:'users'}, isArray: true},
                    getUser: {method:'GET', params: {ext:'users'}},
                    saveUser: {params: {ext:'users'}, method: 'POST'},
                    deleteUser: {params: {ext:'users'}, method: 'DELETE'},

                    //Getting extra user data
                    getUserDivisions:{method:'GET', params: {ext:'users', type:'divisions'}, isArray: true},
                    getUserProjects: {method:'GET', params: {ext:'users', type:'projects'}, isArray: true},

                    //TODO: This endpoint i think is what the normal endpoints should be?
                    //TODO: This should be removed in the future
                    getAbbreviatedUsers: {method:'GET', params: {ext:'clients'}, isArray: true}
                }
            ),

            DivisionUser: $resource(
                config.baseURL +'/organizations/:organizationID/divisions/:divisionID/users/:userID',
                {organizationID: '@organizationID', divisionID: '@divisionID', userID: '@userID'},
                {
                    saveDivisionUser: {method: 'POST'},
                    deleteDivisionUser: {method:'DELETE'},
                    getDivisionUsers: {method:'GET', isArray: true}
                }
            ),

            ProjectUser: $resource(
                config.baseURL +'/projects/:projectID/users/:userID',
                {projectID: '@projectID', userID: '@userID'},
                {
                    getUsers:{method:'GET', isArray: true},
                    getUsersCount: {method:'GET', params: {'countOnly': true}}
                }
            )
        }
    }
})();
