/**
 * @ngdoc object
 * @name Organizations
 * @requires $resource
 */

(function(){

    'use strict';

    angular
        .module('pulse')
        .factory('Organizations', Organizations);

    Organizations.$inject = ['$resource'];

    function Organizations ($resource) {

        return $resource(
            config.baseURL + '/organizations/:id/:ext/:userId/:action/:relationID',
            {id: '@id', ext: '@ext', userId: '@userId', action: '@action', relationID: '@relationID'},
            {
                /*getHandoverProject: {url: config.baseURL + '/organizations/:id/users/:userId/confirmproject/:relationID', method: 'GET'},
                saveConfirmProject: {url: config.baseURL + '/organizations/:id/users/:userId/confirmproject/:relationID', method: 'POST' },
                switchUser: {url: config.baseURL + '/organizations/:id/users/:userId/switchUser', method: 'POST' },*/

                getHandoverProject: {params: {ext:'users', action: 'confirmproject'}, method: 'GET'},

                saveConfirmProject: {params: {ext:'users', action: 'confirmproject'}, method: 'POST'},

                switchUser: {params: {ext:'users', action: 'switchUser'}, method: 'POST'},

                getOrganization:{  method: 'GET',
                                    transformResponse: function(data){
                                        var response = angular.fromJson(data);
                                        return [response];
                                    },
                                    isArray: true
                                }
            }
        );
    }
})();